import React from 'react'

const Manufacturing = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0' style={{ backgroundImage: `url(${require("../Img/ServicesBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '50% 50%', height: "330px" }} >
                </div>
            </section>

            <section>
                <div className="container-lg p-lg-3">
                    <div className="row p-3">
                        <div className='IndustriesMain'>
                            <h2>
                                Our Expertise
                            </h2>
                            <h5>
                                Expertise in Manufacturing
                            </h5>
                            <p>
                                <i>
                                    Enable Digital transformation with superior customer experience as the key goal
                                </i>
                            </p>
                            <p>
                                Wipro helps Industrial & Process Manufacturing businesses in their
                                Digital transformational journey of achieving operational excellence
                                and superior customer experience across the entire value chain through
                                continuous innovation, leveraging a combination of its own IPs and
                                Platforms and the power of an unparalleled ecosystem of partners
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#e8e8e8" }}>
                    <div className="container-lg  CommanIndustriesStories">
                        <h2 className='CommanIndustries'>
                            What We Think
                        </h2>
                        <div className="row  g-4">
                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/manufacturing1.png")} alt="" />
                                            <div className='content'>
                                                <h2> Manufacturing 2025 : Bolder Vision , Stronger Purpose </h2>
                                                <p>
                                                    Download the whitepaper by HBR analytics services in association with
                                                    Wipro to know more about manufacturing services...
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/manufacturing2.png")} alt="" />
                                            <div className='content'>
                                                <h2> Designing a commercial model to unlock revenue potential </h2>
                                                <p>
                                                    With matured outsourcing and the rise in customers’ expectations,
                                                    the service providers are moving towards emerging...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="row g-4">
                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/manufacturing3.png")} alt="" />
                                            <div className='content'>
                                                <h2> Industry 4.0 and the Promise of Mass Customization </h2>
                                                <p>
                                                    As manufacturers advance on their Industry 4.0 journeys,
                                                    industry leaders see both opportunities and challenges when it...
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 ">
                                        <div className="image HoverEffect ">
                                            <img className='' src={require("../Img/manufacturing4.png")} alt="" />
                                            <div className='content'>
                                                <h2> Data Modeling Reveals Sustainability Pathways for Hard-to-Abate Sectors </h2>
                                                <p>
                                                    As governments and enterprises seek to combat climate change,
                                                    one of the most difficult challenges will be reducing...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div>
                    
                </div>
            </section>

        </>
    )
}

export default Manufacturing