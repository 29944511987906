import React from 'react'
import "./Navbar.css"
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 d-md-none d-none d-lg-block ">
                    <nav className="navbar navbar-expand-lg navbar p-0" >
                        <div className="container-lg">
                            <NavLink to='/'>
                                <div className='mb-1'>
                                    <img className='LogoFinalSize' src={require("../Img/ITSCFinalLogo.png")} alt="icloudsoft_logo" />
                                </div>
                            </NavLink>
                            <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" ></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav d-flex justify-content-end ">
                                    <li className="nav-item pe-lg-4" >
                                        <a className="nav-link active " aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item pe-lg-4">
                                        <NavLink className="nav-link " aria-current="page" to="/about">ABOUT US</NavLink>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-3">
                                        <NavLink className="nav-link" to='/services' >
                                            SERVICES
                                        </NavLink>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/itconsulting"> IT CONSULTING </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/manageditservices"> MANAGED SERVICES </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/comprehensive"> COMPREHENSIVE IT SUPPORT </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/remote"> REMOTE SUPPORT & MAINTENANCE </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/itvendor"> IT VENDOR MANAGEMENT </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/infrastructure"> INFRASTRUCTURE SUPPORT SERVICES </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/professional"> PROFESSIONAL SERVER SUPPORT </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/staffingservices"> STAFFING SERVICES </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" to="/helpdesk"> 24/7 HELP DESK </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-3">
                                        <NavLink className="nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                                            SOLUTIONS
                                        </NavLink>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/networkingandinfrastructure"> Networking and Infrastructure Solutions </NavLink>
                                            </li>
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/dmas"> Data Management and Storage Solutions </NavLink>
                                            </li>
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/bpam"> Business Process Automation and Management Solutions </NavLink>
                                            </li>
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/css"> Cyber Security Solutions </NavLink>
                                            </li>
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/erp"> Enterprise Resource Planning (ERP) Systems </NavLink>
                                            </li>
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/crm"> Customer Relationship Management (CRM) Systems </NavLink>
                                            </li>
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/projectmanagementsolutions"> Project Management Solutions</NavLink>
                                            </li>
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/bcdr"> Disaster Recovery and Business Continuity Planning </NavLink>
                                            </li>
                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" to="/wecomm"> Website and E-commerce Solutions </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-3">
                                        <NavLink className="nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                                            INDUSTRIES
                                        </NavLink>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/education"> Education </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/professionalindustries"> Professional </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/financial"> Financial </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/manufacturing"> Manufacturing </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/growing"> Growing </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/technology"> Technology </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/insurance"> Insurance </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" to="/nonprofit"> Non-Profit </NavLink>
                                            </li>

                                        </ul>
                                    </li>

                                    {/* <li className="nav-item dropdown pe-lg-3">
                                        <NavLink className="nav-link" to='/solution' >
                                            PRODUCTS
                                        </NavLink>
                                        <ul className="dropdown-menu ">
                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/solution"> Laptop </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/management"> Desktop </NavLink>
                                            </li>

                                            <li className='border-bottom'>
                                                <NavLink className="dropdown-item text-decoration-none" to="/industries"> Server </NavLink>
                                            </li>

                                            <li className=''>
                                                <NavLink className="dropdown-item text-decoration-none" to="/industries"> Network Rack </NavLink>
                                            </li>

                                        </ul>
                                    </li> */}

                                    <li className="nav-item dropdown pe-lg-2">
                                        <NavLink className="nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            PRODUCTS
                                        </NavLink>
                                        <ul className="dropdown-menu" >
                                            <li>
                                                <NavLink className="dropdown-item text-decoration-none border-bottom" to="/autocard">
                                                    Autocard
                                                </NavLink>
                                            </li>

                                            <li className="nav-item dropend border-bottom">
                                                <NavLink className="dropdown-item dropdown-toggle  text-decoration-none " role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Laptop  &nbsp;&nbsp;&nbsp;&nbsp;
                                                </NavLink>

                                                <ul className="dropdown-menu"  >
                                                    <li><NavLink className="dropdown-item text-decoration-none border-bottom" to="/hardware"> Hardware </NavLink></li>
                                                    <li><NavLink className="dropdown-item text-decoration-none " to="/softwares"> Softwares </NavLink></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <NavLink className="dropdown-item text-decoration-none border-bottom" to="/biomax">
                                                    Biomax
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="dropdown-item text-decoration-none bg-black" to="/smartoffice">
                                                    Smart Office
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>


                                    <li className="nav-item pe-lg-4">
                                        <NavLink className="nav-link " aria-current="page" to="/contact">Contact Us</NavLink>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-3">
                                        <NavLink className="nav-link" >
                                            LOGIN
                                        </NavLink>
                                        <ul className="dropdown-menu">
                                            <li className='border-bottom '>
                                                <a className="dropdown-item text-decoration-none" href="/login"> Employe Login </a>
                                            </li>

                                            <li className=''>
                                                <a className="dropdown-item text-decoration-none" href="/customerlogin"> Customer Login </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-lg-none d-md-block" style={{ backgroundColor: "#4C4C4C" }} >
                    <nav className="navbar navbar-expand-lg p-3" >
                        <NavLink to='/'>
                            <div className='mb-1'>
                                <img className='LogoFinalSize' src={require("../Img/ITSCFinalLogo.png")} alt="icloudsoft_logo" />
                            </div>
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa-solid fa-bars " style={{ color: "#fff" }}></i>
                        </button>
                        <div className="collapse navbar-collapse w-100 p-0 mt-4" id="navbarNavDropdown">
                            <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4 mb-3" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "95%", marginLeft: "8px" }} >
                                <li className='nav-item text-start pb-2 mt-2' >
                                    <NavLink className="text-white text-decoration-none fs-6  " to="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </NavLink>
                                </li>

                                <li className='nav-item text-start pb-2 mt-2' >
                                    <NavLink className="text-white text-decoration-none fs-6  " to="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > About Us </b> </NavLink>
                                </li>

                                <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Services </b></div>
                                    <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ borderRadius: "0px 0px 0px 0px", backgroundColor: "#4C4C4C" }} id="submenu">
                                        <li className='nav-item '>
                                            <div className=' '>
                                                <div className="row d-flex justify-content-start w-100 ">
                                                    <div className="">
                                                        <NavLink className='text-decoration-none' to="/itconsulting">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> IT CONSULTING </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/manageditservices">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> MANAGED SERVICES </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/comprehensive">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> COMPREHENSIVE IT SUPPORT </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/remote">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> REMOTE SUPPORT & MAINTENANCE </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/itvendor">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> IT VENDOR MANAGEMENT </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/infrastructure">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> INFRASTRUCTURE SUPPORT SERVICES </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/professional">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> PROFESSIONAL SERVER SUPPORT </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/staffingservices">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> STAFFING SERVICES </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/helpdesk">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> 24/7 HELP DESK </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Solutions </b></div>
                                    <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ borderRadius: "0px 0px 0px 0px", backgroundColor: "#4C4C4C" }} id="submenu">
                                        <li className='nav-item '>
                                            <div className=' '>
                                                <div className="row d-flex justify-content-start w-100 ">
                                                    <div className="">
                                                        <NavLink className='text-decoration-none' to="/networkingandinfrastructure">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Networking and Infrastructure  </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/dmas">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Data Management and Storage  </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/bpam">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> BPA and Management  </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/css">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Cyber Security  </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/erp">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> ERP </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/crm">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> CRM </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/projectmanagementsolutions">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Project Management  </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/bcdr">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> BCDR </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/wecomm">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Website and E-commerce  </b></div>
                                                            <div className="" ></div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Industries </b></div>
                                    <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ borderRadius: "0px 0px 0px 0px", backgroundColor: "#4C4C4C" }} id="submenu">
                                        <li className='nav-item '>
                                            <div className=' '>
                                                <div className="row d-flex justify-content-start w-100 ">
                                                    <div className="">
                                                        <NavLink className='text-decoration-none' to="/education">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Education  </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/professionalindustries">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Professional  </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/financial">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Financial </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/manufacturing">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Manufacturing </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/growing">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Growing </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/technology">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Technology </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/insurance">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Insurance </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/nonprofit">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Non-Profit </b></div>
                                                            <div className="" ></div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                    <div className="dropdown-toggle  fs-6 " style={{ fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Products </b></div>
                                    <ul className="dropdown-menu nav collapse p-2 " aria-labelledby="mega-one" style={{ borderRadius: "0px 0px 0px 0px", backgroundColor: "#4C4C4C" }} id="submenu">
                                        <li className='nav-item '>
                                            <div className=' '>
                                                <div className="row d-flex justify-content-start w-100 ">
                                                    <div className="">
                                                        <NavLink className='text-decoration-none' to="/networkingandinfrastructure">
                                                            <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Autocard  </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <div className="dropdown-item border-bottom" style={{ fontSize: "15.5px", fontWeight: "600", color: "#2bc0c1" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Laptops </b>
                                                            <NavLink className='text-decoration-none ' to='/hardware'>
                                                                <div className='dropdown-item text-white pt-3'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Hardware  </b></div>
                                                                <div className="border-bottom" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/bpam">
                                                                <div className='dropdown-item text-white '><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Software </b></div>
                                                                <div className="" ></div>
                                                            </NavLink>
                                                        </div>
                                                        <NavLink className='text-decoration-none' to="/css">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Biomax </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                        <NavLink className='text-decoration-none' to="/erp">
                                                            <div className='dropdown-item text-white'><b className='' style={{ fontWeight: "400", fontSize: "12.5px", letterSpacing: "0px" }}> Smart Office </b></div>
                                                            <div className="border-bottom" ></div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <li className='nav-item text-start pb-2 mt-2' >
                                    <NavLink className="text-white text-decoration-none fs-6  " to="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </NavLink>
                                </li>

                                <li className='nav-item text-start pb-2 mt-2' >
                                    <NavLink className="text-white text-decoration-none fs-6  " to="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </NavLink>
                                </li>

                                <li className='nav-item text-start pb-2 mt-2' >
                                    <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </NavLink>
                                </li>

                            </ul>
                        </div>

                    </nav>


                </div>
            </section>

        </>
    )
}

export default Navbar